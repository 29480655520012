import * as React from "react";

import { ProductPreviewContext } from "./ProductPreviewProvider";
import { BrandProductsContext } from "../brand/BrandProductsProvider";
import { ProductCategoryContext } from "../providers/ProductCategoryProvider";
import useCategoryFiltersReducer from "../productFilters/useCategoryFiltersReducer";
import useProductFilterReducer, { inActiveFilterCount } from "../productFilters/useProductFilterReducer";
import { useAnalyticsStore } from '../../App';
import { getSortPrice } from "./DisplayPrice";

// These are for the Flower "category" filter
export const FLOWER_CATEGORY_NAME = "Flower";
export const WHOLE_FLOWER_FILTER_NAME = "Whole Flower"; // Not Small Buds etc.
export const SMALL_BUDS_FILTER_NAME = "Small Buds";
export const PRE_GROUND_FILTER_NAME = "Pre-Ground";
// Note: 'Whole Flower' is flower that is not small buds or pre-ground
export const FLOWER_TYPE_FILTERS = [WHOLE_FLOWER_FILTER_NAME, SMALL_BUDS_FILTER_NAME, PRE_GROUND_FILTER_NAME];

export const isPreGround = (product) => {
  // Name can be more reliable that tags
  const name = product.display_info.name.toLowerCase();
  return name.indexOf('pre-ground') > -1 || product.is_pre_ground
}

export const isSmallBuds = (product) => {
  // Name can be more reliable that tags
  const name = product.display_info.name.toLowerCase();
  return name.indexOf('small buds') > -1 || product.is_small_buds
}

export const subCategoryAll = {
  name: 'all',
  display_name: 'All',
  parent_category_name: "none"
};

// show all brands/types/weights/pricing
export const SHOW_ALL = 'all';
// show sale items value
export const SALE_ONLY = 'sale';
// THC sort filter values
export const THC_SORT = 'thc';
export const THC_SORT_LOW = 'thcLow';
// PRICE sort filter values
export const PRICE_SORT = 'price';
export const PRICE_SORT_HIGH = 'priceHigh';

export const FilteredProductsContext = React.createContext({});
/**
 * This provider is used for the dispensary page filtered products view
 *
 * NOTE: This provider works from category.display_name
 * rather than category.name.  This is related to optionally
 * pulling the category from the url (/Edibles).
 *
 * @param {string} brandName - optional for brand page
 */
const FilteredProductsProvider = ({brandName, children}) => {

  const { trackError } = useAnalyticsStore();

  const { defaultCategory } = React.useContext(ProductCategoryContext);
  // Filters for the current SubCategory
  const [filters, updateFilters] = useCategoryFiltersReducer();
  // The current filter state
  const [filterState, dispatch] = useProductFilterReducer();

  // Support BRAND pages by using the brand-specific product provider
  const productContext = brandName ? BrandProductsContext : ProductPreviewContext;
  const { productsByCategory } = React.useContext(productContext);

  // State for currently filtered products
  const [currentCategory, setCurrentCategory] = React.useState(); // null renders Spinner
  const [loading, setLoading] = React.useState();

  /**
   * All dispensary products keyed by category name
   * (preserve during re-renders)
   */
  const productsByCategoryMap = React.useRef();
  // Dispensary poducts by category with filters applied, undefined state matters!
  const [filteredProducts, setFilteredProducts] = React.useState();

  const sortProducts = React.useCallback((productA, productB, sortBy) => {
    if ([THC_SORT, THC_SORT_LOW].includes(sortBy)) {
      const prodBTest = productB?.display_info.lab_results?.find(test => test.labTest === 'THC') || { value:0, labResultUnit: "Percentage" };
      const prodATest = productA?.display_info.lab_results?.find(test => test.labTest === 'THC') || { value:0, labResultUnit: "Percentage" };
      return parseInt(prodATest.value,10) > parseInt(prodBTest.value,10)
        ? sortBy === THC_SORT ? -1 : 1
        : sortBy === THC_SORT ? 1 : -1;
    } else if ([PRICE_SORT, PRICE_SORT_HIGH].includes(sortBy)) {
      return getSortPrice(productA) > getSortPrice(productB)
        ? sortBy === PRICE_SORT ? 1 : -1
        : sortBy === PRICE_SORT ? -1 : 1;
    } else {
      return 0; // Skip sort
    }
  }, []);

  // INITIAL PAGE RENDER PRODUCTS (used by the Home Page as well!)
  const setInitialState = React.useCallback((byCategory) => {
    productsByCategoryMap.current = byCategory;
    // 1/9/23 removed: currentCategory ||
    const initialCategory = defaultCategory.display_name;

    setFilteredProducts(byCategory.get(initialCategory));
    setCurrentCategory(initialCategory);

    // Undo any filters
    dispatch({type:'resetFilters'});
    // Update available filters
    updateFilters({
      type: 'updateCategory',
      categoryName: initialCategory,
      productMap: byCategory,
    });
    setLoading(false);
  }, [defaultCategory, dispatch, updateFilters]);

  // Wait for initial product data fetch
  React.useEffect(() => {
    // Load all products for the dispensary
    if (productsByCategory &&
        defaultCategory?.display_name &&
        !productsByCategoryMap.current) {
      setLoading(true);
      try {
        setInitialState(productsByCategory);
      } catch(error) {
        trackError('error_fetching_products');
      }
      setLoading(false);
    }
    return () => {
      setLoading(false);
    }
  }, [trackError, defaultCategory, currentCategory, productsByCategory, setInitialState]);

  // Called by consumer on initial render
  const setCategory = React.useCallback((categoryName) => {
    if (productsByCategoryMap.current &&
        categoryName !== currentCategory) {

      // 1/9/23 removed: setFilteredProducts(productsByCategoryMap.current.get(categoryName));
      setCurrentCategory(categoryName);

      // Reset selected filters
      dispatch({type:'resetFilters'});
      // Update filter options
      updateFilters({
        type: 'updateCategory',
        categoryName,
        productMap: productsByCategoryMap.current,
      });
    }
  }, [currentCategory, dispatch, updateFilters]);

  // Filter the products
  const setFilters = (filter) => {
    const type = Object.keys(filter)[0];
    const value = filter[type];
    dispatch({ type, value });
  }

  // Utility match methods
  const matchesSubCategory = (product, subCategoryName) => {
    return subCategoryName === subCategoryAll.name ||
      product.display_info.sub_category?.name === subCategoryName;
  };

  const matchesWeight = (product, weight) => {
    return weight === SHOW_ALL ||
      product.grams === weight;
  };

  const matchesBrand = (product, brand) => {
    return brand === SHOW_ALL ||
      product.display_info.brand === brand;
  };

  const matchesType = (product, type) => {
    return type === SHOW_ALL ||
      product.display_info.cannabis_type === type;
  };

  // Only displays for Flower Category
  // Value can only be changed from SHOW_ALL when category is Flower
  const matchesFlowerType = (product, flowerType) => {
    switch(flowerType) {
      case WHOLE_FLOWER_FILTER_NAME:
        return !isSmallBuds(product) && !isPreGround(product);
      case SMALL_BUDS_FILTER_NAME:
        return isSmallBuds(product);
      case PRE_GROUND_FILTER_NAME:
        return isPreGround(product);
      default:
        return true; // SHOW_ALL
    }
  }

  const matchesPricing = (product, pricing) => {
    return pricing === SHOW_ALL ||
      (pricing === SALE_ONLY && product.discount_data?.cost_usa_cents_discounted > 0);
  };

  const matchesMood = (product, mood) => {
    return mood === SHOW_ALL ||
      product.display_info.moods?.includes(mood);
  };

  // Update the filteredProducts whenever the filters change
  React.useEffect(() => {
    // Prevent setting filtered products (empty) prior to product load
    if (loading) {
      return;
    }
    const categoryProducts = productsByCategoryMap.current
      ? productsByCategoryMap.current.get(currentCategory)
      : undefined;

    /**
     * Optimize initial display
     *
     * Only filter products if filters are IN USE
     * !!! ANY TIME A FILTER IS ADDED, UPDATE THE FILTER COUNT HERE !!!
     *
     * FILTER_COUNT excludes subCategory but includes "sorting"
     * There are 6 "matches" filters below + sorting = 7.
     */
    const FILTER_COUNT = 7;
    const noFiltersInUse = filterState.subCategory.name === SHOW_ALL &&
      inActiveFilterCount(filterState) === FILTER_COUNT;

    const filterMatches = noFiltersInUse
      ? categoryProducts
      : categoryProducts.filter(product => {
          return matchesSubCategory(product, filterState.subCategory.name) &&
            matchesWeight(product, filterState.grams) &&
            matchesBrand(product, filterState.brand) &&
            matchesType(product, filterState.type) &&
            matchesFlowerType(product, filterState.flowerType) &&
            matchesPricing(product,filterState.pricing) &&
            matchesMood(product, filterState.mood)
        });

    // Set and optionally SORT the filtered products!
    setFilteredProducts(
      filterState.sorting === SHOW_ALL
        ? filterMatches
        : filterMatches.sort((a,b) => sortProducts(a,b, filterState.sorting))
    );
  }, [loading, filterState, currentCategory, sortProducts]);

  const resetFilters = () => {
    dispatch({type:'resetFilters'});
  };

  // TODO: Provide filterState and filters and collapse
  // individual items like "brand" etc.
  return (
    <FilteredProductsContext.Provider value={{
      filteredProducts,
      filterState,
      categoryName: currentCategory,
      subCategory: filterState.subCategory,
      subCategories: filters.subCategories,
      grams: filterState.grams,
      subCategoryWeights: filters.subCategoryWeights,
      brand: filterState.brand,
      subCategoryBrands: filters.subCategoryBrands,
      type: filterState.type,
      subCategoryTypes: filters.subCategoryTypes,
      flowerType: filterState.flowerType,
      subCategoryFlowerTypes: filters.subCategoryFlowerTypes,
      pricing: filterState.pricing,
      mood: filterState.mood,
      subCategoryMoods: filters.subCategoryMoods,
      hasSubCategorySaleItems: filters.subCategoryHasSaleItems,
      sorting: filterState.sorting,
      hasSubCategoryTHCItems: filters.subCategoryHasTHCItems,
      setCategory,
      setFilters,
      resetFilters,
      loading
    }}>
      {children}
    </FilteredProductsContext.Provider>
  );
};

export default FilteredProductsProvider;
