import * as React from 'react';

import { dollarDisplay } from '../providers/OrderPricingProvider';
import { slotDiscountForLocation } from './timeslot-utils';
import { getFormattedHourLabel as hourLbl} from '../deliveryWindows/scheduled-delivery-utils';
import { UserContext } from '../providers/UserProvider';
import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import useNextAvailableTimeSlot from './useNextAvailableTimeSlot';

import styles from '../home/ProductCarousel.module.css';

/**
 * Currently slot data is only loaded on initial app load
 * and available slots are determined by updating the display usung the
 * current time.
 *
 * Ideally for this messaging we would refetch the slot data (DeliveryOptionsProvider)
 * at regular intervals
 */
const DisplayNextDiscountedSlot = () => {

  const { currentLocation } = React.useContext(UserContext);
  const { timeSlotsByDay } = React.useContext(DeliveryOptionsContext);
  const { nextDiscountedSlot } = useNextAvailableTimeSlot(timeSlotsByDay);

  const [discountDisplay, setDiscountDisplay] = React.useState();

  React.useEffect(() => {
    if (nextDiscountedSlot) {
      const { hours, incentive } = nextDiscountedSlot;
      const slotLabel = `${hourLbl(hours.start_hour)}-${hourLbl(hours.end_hour)}`;
      const discount = slotDiscountForLocation(incentive, currentLocation);
      const discountDollars = dollarDisplay(discount, true);
      setDiscountDisplay(discount > 0
        ? `${discountDollars} OFF ${slotLabel} Delivery!`
        : undefined
      );
    } else {
      setDiscountDisplay(undefined);
    }
  }, [currentLocation, nextDiscountedSlot]);

  return (discountDisplay
    ? <div className={styles.slotDiscountCallout}>
        {discountDisplay}
      </div>
    : null
  )
}

export default DisplayNextDiscountedSlot;
