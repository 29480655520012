import * as React from 'react';

import { CartItemsContext } from './CartItemsProvider';
import styles from './CartItem.module.css';

import PropTypes from 'prop-types';

/**
 * Label bundle deals at chackout
 */
const CartBundleLabel = ({
  product,
  withStyle={},
}) => {

  const { bundlePricingMap } = React.useContext(CartItemsContext);

  return (product.bundle_deal_data &&
          bundlePricingMap?.get(product.bundle_deal_data.id))
    ? <span className={styles.bundleLbl} style={withStyle}>
        {product.bundle_deal_data.id}
      </span>
    : null
}

CartBundleLabel.propTypes = {
  product: PropTypes.object.isRequired,
  withStyle: PropTypes.object
}

export default  CartBundleLabel;
