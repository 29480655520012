import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import ProductName from './ProductName';
import LabResultsForCard from './LabResultsForCard';
import { SEOLinkWrapper } from '../routing/router-utils';
import CroppedProductImage  from './CroppedProductImage';
import { flattenedProduct } from './product-utils';
import PriceDetails from './PriceDetails';
import BizOwnerTooltip from '../common/BizOwnerTooltip';
import SoldOutRibbon from './SoldOutRibbon';
import OrderLimitPill, { imageOverlayStyle } from './OrderLimitPill';
import PropTypes from 'prop-types';

import defaultStyles from './ProductCardForGrid.module.css';
import orderItemStyles from './ProductCardOrderItem.module.css';

export const STYLE_MODULE = {
  DEFAULT: defaultStyles,
  ORDER_ITEM: orderItemStyles
}

const ProductCardForGrid = ({
  Id,
  product,
  orderQuantity, // Order history only
  isFullWidth,
  styleModule,
  handleDetailClick,
  handleCartClick,
}) => {

  const {
    name,
    image_url,
    brand,
    category={},
    cannabis_type,
    lab_results,
    per_order_max_quantity,
    quantity_available,
    display_sold_out_status,
  } = flattenedProduct(product);

  // NOTE: All classNames are implemented in both STYLE_MODULES
  // This was done as a quick fix for the order item layout
  // Refactoring is a pain with this, TODO: simplify
  const styles = styleModule || STYLE_MODULE.DEFAULT;

  const { trackEvent } = useAnalyticsStore();

  const handleAddToCart = (quantity) => {
    handleCartClick(quantity);
    trackEvent('product_listing_add_to_cart');
  }

  // The deep link for SEO or email/promo use
  const deepLink = `/product/${Id}`;

  // display in Your Orders etc.
  const widthStyle = isFullWidth ? styles.fullWidth : '';
  const showLabResults = lab_results?.length > 0;

  return (
    <div className={`${styles.productCardWrap} ${widthStyle}`}  key={`Inline-${Id}`}>
      <SEOLinkWrapper deepLink={deepLink} key={Id} styleClass={styles.productCard} handleClick={handleDetailClick}>
        <div className={styles.productImageWrap}>
          <CroppedProductImage
            productName={name}
            src={image_url}
            category={category.display_name}
          />
          <OrderLimitPill
            per_order_max_quantity={per_order_max_quantity}
            withStyle={imageOverlayStyle} />
        </div>

        <div className={styles.productBody}>
          { brand &&
            <div className={styles.brand}>
              <span>{brand}</span>
              <BizOwnerTooltip businessName={brand} />
            </div>
          }

          <div className={styles.productName}>
            <ProductName name={name} cannabisType={cannabis_type} />
          </div>

          { showLabResults &&
            <div className={styles.productInfo}>
              <LabResultsForCard
                productId={Id}
                categoryName={category.display_name}
                lab_results={lab_results} />
            </div>
          }
          <div className={styles.mobileControls}>
            <PriceDetails
              product={product}
              orderQuantity={orderQuantity} /* order history only */
              withCartHint
              handleAddToCart={handleAddToCart} />
          </div>
        </div>
        {/* Desktop layout */}
        <div className={styles.desktopControls}>
          <PriceDetails
            product={product}
            orderQuantity={orderQuantity} /* order history only */
            withCartHint
            handleAddToCart={handleAddToCart} />
        </div>
      </SEOLinkWrapper>
      <SoldOutRibbon
        quantity_available={quantity_available}
        display_sold_out_status={display_sold_out_status} />
    </div>
  )
};

ProductCardForGrid.propTypes = {
  Id: PropTypes.string.isRequired,
  product: PropTypes.object,
  orderQuantity: PropTypes.number, // Prior orders view only
  isFullWidth: PropTypes.bool,
  styleModule: PropTypes.object,
  handleDetailClick: PropTypes.func.isRequired,
  handleCartClick: PropTypes.func.isRequired,
};

export default ProductCardForGrid;
