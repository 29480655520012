import * as React from 'react';

import { dollarDisplay } from '../providers/OrderPricingProvider';
import BundlePricingTooltip from '../bundles/BundlePricingTooltip';
import PropTypes from 'prop-types';
import styles from './ProductPreviewCard.module.css';

const calcPctOff = (origPrice, salePrice) => {
  return Math.floor(((origPrice - salePrice)/origPrice) * 100);
};

/**
 *  25% Off sale label for product cards
 *  NOTE: % must be divisible by 5 or we fall back to
 *  "dollar discount" display.
 *
 *  Also support per_order_max_quantity (e.g. 1)
 *  Also support bundled pricing
 */
const SaleLabelBottom = ({
  cost_usa_cents,
  discount_data,
  bundle_deal_data,
  per_order_max_quantity,
  isSuggestedProduct=false}) => {

  const [saleLabel, setSaleLabel] = React.useState();
  // Per order quantity limit
  const [qtyLimit, setQtyLimit] = React.useState();
  const [isBundlePricing, setIsBundlePricing] = React.useState();

  React.useEffect(() => {
    if (bundle_deal_data) {
      setSaleLabel('Bulk Discounts!');
      setIsBundlePricing(true);
    } else {
      const { cost_usa_cents_discounted } = discount_data || {};
      if (cost_usa_cents_discounted) {
        const pct = calcPctOff(cost_usa_cents, cost_usa_cents_discounted);
        if (pct % 5 === 0) {
          setSaleLabel(`${pct}% Off!`);
        } else {
          const dollarAmt = dollarDisplay(cost_usa_cents - cost_usa_cents_discounted, true);
          setSaleLabel(`${dollarAmt} Off!`);
        }
      }
    }
  },[cost_usa_cents, bundle_deal_data, discount_data]);

  // Handle 1-per-order sale items
  React.useEffect(() => {
    if (per_order_max_quantity > 0) {
      setQtyLimit(per_order_max_quantity);
    }
  }, [per_order_max_quantity])

  const addlClass = qtyLimit
    ? styles.qtyLimit
    : isBundlePricing
      ? styles.qtyDiscount
      : '';

  return ( saleLabel || qtyLimit
    ? <div className={isBundlePricing ? styles.saleLabelWithTooltip : ''}>
        { isBundlePricing &&
          <BundlePricingTooltip
            cost_usa_cents={cost_usa_cents}
            bundle_deal_data={bundle_deal_data}
            withStyle={{margin: '0 0 8px 12px'}} />
        }
        <div className={`${styles.saleLabel} ${addlClass}`}
             data-is-suggested={isSuggestedProduct}>
          {/* Limit trumps sale label */}
          { qtyLimit
            ? `Limit: ${qtyLimit} Per Order`
            : saleLabel
          }
        </div>
      </div>
    : null
  )
}

SaleLabelBottom.propTypes = {
  cost_usa_cents: PropTypes.number.isRequired,
  discount_data: PropTypes.object,
  bundle_deal_data: PropTypes.object,
  per_order_max_quantity: PropTypes.number,
  isSuggestedProduct: PropTypes.bool,
}

export default SaleLabelBottom;
