import * as React from 'react';

import { DiscountAndCreditContext, DiscountType } from '../providers/DiscountAndCreditProvider';
import useCustomerOrders from '../orders/useCustomerOrders';
import { UserContext } from '../providers/UserProvider';
import { CartItemsContext } from './CartItemsProvider';
import { OrderPricingContext, calcTotalTaxCents, dollarDisplay } from '../providers/OrderPricingProvider';

export const dollarsOnly = (cents) => {
  return Math.round(cents/100);
};

/**
 * Calculate Taxes, Fees and Discounts based on the currently carted items
 * NOTE: This hook is only useable at checkout since OrderPricingCalcs
 * is a /checkout-only provider
 */
const useOrderPricingCalcs = () => {

  // Checkout-only context
  const {
    loading: feesLoading,
    salesTaxPct,
    nonCannabisSalesTaxPct,
    cannabisTaxPct,
    serviceFeeCents,
    deliveryFeeCents,
    deliveryFeeDisplay,
    noDeliveryFeeThresholdCents,
    smallOrderFeeCents,
    smallOrderThresholdCents,
  } = React.useContext(OrderPricingContext);

  // Global context
  const {
    discountCode,
    discountCodeType,
    discountCodeValue,
    discountCodeLabel,
    aeropayCreditCents=0,
    windowDiscountCents=0,
    activeCredit,
  } = React.useContext(DiscountAndCreditContext);

  const { user } = React.useContext(UserContext);
  const { isFreeDeliveryOrder } = useCustomerOrders(user);
  const { itemTotal, nonCannabisItemTotal } = React.useContext(CartItemsContext);
  const [discountCodeCreditCents, setDiscountCodeCreditCents] = React.useState(0);
  const [referCreditCents, setReferCreditCents] = React.useState(0);
  const [deliveryFeeApplied, setDeliveryFeeApplied] = React.useState(0);
  const [smallOrderShortfallCents, setSmallOrderShortfallCents] = React.useState(0);
  const [smallOrderFeeApplied, setSmallOrderFeeApplied] = React.useState(0);
  const [taxCents, setTaxCents] = React.useState(0);
  const [serviceFeesAndTaxesCents, setServiceFeesAndTaxesCents] = React.useState(0);
  const [preDiscountSubTotalCents, setPreDiscountSubTotalCents] = React.useState(0);
  const [subTotalCents, setSubTotalCents] = React.useState(0);
  const [totalPriceCents, setTotalPriceCents] = React.useState(0);

  React.useEffect(() => {
    if ( typeof itemTotal === "number" && !feesLoading ) {

      // used later in subtotal calcs
      let discountCodeCredit = 0;
      if (discountCodeType && typeof itemTotal === "number") {
        if (discountCodeType === DiscountType.FIXED) {
          discountCodeCredit = discountCodeValue;
        } else if (discountCodeType === DiscountType.PERCENTAGE) {
          discountCodeCredit = Math.floor(itemTotal * (discountCodeValue/100));
        }
        setDiscountCodeCreditCents(discountCodeCredit);
      } else {
        setDiscountCodeCreditCents(0);
      }

      // Used later in subtotal calcs
      let referCredit = 0;
      if (activeCredit?.value.usa_cents && typeof itemTotal === "number") {
        referCredit = activeCredit.value.usa_cents;
        // Addl defensive code: Discount code trumps credits here
        if (discountCodeCredit === 0) {
          setReferCreditCents(activeCredit.value.usa_cents);
        }
      } else {
        setReferCreditCents(0);
      }

      // Item Total with no discounts
      setPreDiscountSubTotalCents(itemTotal);

      // Item total with ALL discounts
      // NOTE: Code and Refer Credit cannot be combined!
      const discountTotal = (discountCodeCredit || referCredit) +
                            aeropayCreditCents + windowDiscountCents;
      const totalAfterDiscounts = itemTotal - discountTotal;
      setSubTotalCents(totalAfterDiscounts);

      // Total Taxes
      // Non-cannabis sales tax is lower than normal sales tax (6.25 vs 9.25)
      const taxes = calcTotalTaxCents(
        itemTotal,
        nonCannabisItemTotal,
        discountTotal,
        salesTaxPct,
        nonCannabisSalesTaxPct,
        cannabisTaxPct);
      setTaxCents(taxes);

      // Delivery fee and Small Order (Delivery) fees needed mostly for the total calc
      const applicableDeliveryFee = isFreeDeliveryOrder || itemTotal >= noDeliveryFeeThresholdCents
        ? 0
        : deliveryFeeCents;
      setDeliveryFeeApplied(applicableDeliveryFee);

      const applicableSmallOrderFee = itemTotal < smallOrderThresholdCents
        ? smallOrderFeeCents
        : 0;
      setSmallOrderFeeApplied(applicableSmallOrderFee);

      // Additional product cost needed to avoid small order fee
      setSmallOrderShortfallCents(
        itemTotal < smallOrderThresholdCents
        ? smallOrderThresholdCents - itemTotal
        : 0
      );

      const feesAndTaxes = taxes +
                           serviceFeeCents +
                           applicableSmallOrderFee;
      setServiceFeesAndTaxesCents(feesAndTaxes);

      const totalPrice = totalAfterDiscounts +
                         feesAndTaxes +
                         applicableDeliveryFee;
      setTotalPriceCents(totalPrice);
    }
  }, [user, itemTotal, nonCannabisItemTotal, activeCredit, feesLoading,
      isFreeDeliveryOrder, discountCodeType, discountCodeValue, salesTaxPct,
      aeropayCreditCents, windowDiscountCents, cannabisTaxPct,
      nonCannabisSalesTaxPct, serviceFeeCents, smallOrderFeeCents,
      smallOrderThresholdCents, deliveryFeeCents, noDeliveryFeeThresholdCents
    ]);

  return {
    taxCents,
    serviceFeeCents,
    deliveryFeeCents,
    deliveryFeeDisplay,
    deliveryFeeApplied,
    deliveryFeeSavings: deliveryFeeCents - deliveryFeeApplied, /* for validation */
    showFreeDeliveryTip: deliveryFeeApplied > 0,
    freeDeliveryThresholdDisplay: dollarsOnly(noDeliveryFeeThresholdCents),
    isFreeDeliveryOrder,
    smallOrderShortfallCents,
    smallOrderShortfallDisplay: dollarDisplay(smallOrderShortfallCents),
    smallOrderFeeApplied,
    smallOrderFeeDisplay: dollarsOnly(smallOrderFeeCents),
    serviceFeesAndTaxesCents,
    serviceFeesAndTaxesDisplay: dollarDisplay(serviceFeesAndTaxesCents),
    discountCode,       // SAVE15
    discountCodeLabel,  // 15% OFF
    discountCodeCreditCents,
    discountCodeCreditDisplay: dollarDisplay(discountCodeCreditCents),
    activeCredit,    // via DiscountAndCreditProvider
    referCreditCents,
    referCreditDisplay: dollarDisplay(referCreditCents),
    aeropayCreditCents,
    aeropayCreditDisplay: dollarDisplay(aeropayCreditCents),
    windowDiscountCents,
    windowDiscountDisplay: dollarDisplay(windowDiscountCents),
    totalDiscountCents: preDiscountSubTotalCents -  subTotalCents, /* needed for tax calcs! */
    preDiscountSubTotalCents,
    preDiscountSubTotalDisplay: dollarDisplay(preDiscountSubTotalCents),
    subTotalCents,
    subTotalDisplay: dollarDisplay(subTotalCents),
    totalPriceCents,
    totalPriceDisplay: dollarDisplay(totalPriceCents)
  };
}

export default useOrderPricingCalcs;
