import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { useAnalyticsStore } from '../../App';
import { ModalType, ModalContext } from '../modal/ModalProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { getCartItemTotalDisplay } from './product-utils';
import AdjustQuantity from './AdjustQuantity';
import { flattenedProduct } from './product-utils';
import Button from '../styleguide/Button';
import DisplayPrice from './DisplayPrice';
import BulkPriceDetailsForModal from '../bundles/BulkPriceDetailsForModal';
import useSoldOutStatus from './useSoldOutStatus';
import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  addToCartButton: {
    width:'100%',
    margin:'1rem 0',
  },
  saleMsg: {
    textAlign: 'center',
    font: 'var(--zrFontSemi)',
    width: '100%',
    paddingBottom: 6,
    marginTop: -3,
  },
  soldOut: {
    margin: '0 0 .5rem',
  },
  soldOutMsg: {
    width: '100%',
    margin: '0 0 .5rem',
  },
  soldOutHdr: {
    font: 'var(--zrFontSemiItalic)',
  },
  chatMsg: {
    margin: '.25rem 0',
  }
});

/**
 * Add to Cart, show quantity price on button
 *
 * Currently used only on the product detail card/modal
 */
const AddToCartButton = ({
  product,
  onAddToCart,
  isStacked
}) => {

  const classes = useStyles();

  const {
    id: itemId,
    name,
    category,
    quantity_available,
    display_sold_out_status,
    bundle_deal_data,
    discount_data,
  } = flattenedProduct(product);

  // This is actually Qty = 1, disabled for purchase, but available for display
  const isSoldOut = useSoldOutStatus(
    quantity_available,
    display_sold_out_status);

  const { trackEvent } = useAnalyticsStore();
  const { hasNoLocation } = React.useContext(UserContext);
  const { displayModal } = React.useContext(ModalContext);
  const { addItem } = React.useContext(CartItemsContext);

  const [itemCount, setItemCount] = React.useState(1);
  const [displayTotal, setDisplayTotal] = React.useState();

  const addToCartClick = () => {
    trackEvent('product_modal_add_to_cart_click');
    if (hasNoLocation && !window.Cypress) {
      // Require an address to cart items
      displayModal(
        ModalType.ADDRESS, {
          trackContext: 'address_from_cart_button',
          continueModal: ModalType.PRODUCT,
          itemId,
          itemCount,
          title: name,
          categoryName: category.display_name
          /**
           * TODO FIXME: switch to useReducer for flow
           * relatedProducts: data missing for suggested product carousel
           */
        });
    } else {
      addItem(itemId, product, itemCount);
      if (onAddToCart) {
        onAddToCart();
      }
    }
  };

  // Handle button price
  React.useEffect(() => {
    setDisplayTotal(
      getCartItemTotalDisplay(product, itemCount)
    );
  }, [product, itemCount]);

  return (
    isSoldOut
      ? null
      : <>
          <div>
            <AdjustQuantity
              count={itemCount}
              setCount={setItemCount}
              isStacked={isStacked} />
          </div>
          <div style={{flex:1}}>
            <Button text={`Add to Cart - ${displayTotal}`}
              handleClick={addToCartClick}
              withClass={classes.addToCartButton} />
          </div>
          { bundle_deal_data
            ? <BulkPriceDetailsForModal
                product={product} />
            : discount_data
              ? <div className={classes.saleMsg}>
                  On Sale!
                  {' '}
                  <DisplayPrice
                    product={product}
                    quantity={itemCount} />
                </div>
              : null
          }
        </>
  )
}

AddToCartButton.propTypes = {
  product: PropTypes.object.isRequired,
  onAddToCart: PropTypes.func.isRequired, /* runs after carting */
  isStacked: PropTypes.bool
}

export default AddToCartButton;
